.App {
  text-align: center;
  background: #023440;
  min-height: 100vh;
  color: #F27405;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #011C26;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #D95204;
  padding: 40px 0;
  -webkit-box-shadow: inset 0px -110px 42px -121px rgba(0,0,0,0.75);
  -moz-box-shadow: inset 0px -110px 42px -121px rgba(0,0,0,0.75);
  box-shadow: inset 0px -110px 42px -121px rgba(0,0,0,0.75);
}

.App-title {
  font-size: 1.5em;
}

.App-title em {
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
}

.App-subtitle {
  font-weight: 200;
}

section {
  width: 100%;
}

section > div {
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 0;
}

.NumberCounter .counter {
  font-weight: 800;
  font-size: 2em;
}

.tabContainer {
  background: rgba(0, 0, 0, .1);
  padding-bottom: 5px;
  padding-top: 1px;
}

.tabSelector {
  display: flex;
  width: 100%;
  height: 40px;
  line-height: 40px;
  list-style: none;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
}

.tabSelector > * {
  padding: 0 10px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.tabSelector > *.react-tabs__tab--selected {
  border-color: #F27405;
}

h1 {
  font-size: 2em;
}
h2 {
  font-size: 1.5em;
}

a {
  color: inherit;
  text-decoration: underline dotted currentColor;
}

footer {
  background: #020d14;
  color: rgba(255, 255, 255, .2);
  padding: 10px 5px;
}
